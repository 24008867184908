import { render, staticRenderFns } from "./LiveNoStartCenter.vue?vue&type=template&id=10fadd6b&scoped=true"
import script from "./LiveNoStartCenter.vue?vue&type=script&lang=js"
export * from "./LiveNoStartCenter.vue?vue&type=script&lang=js"
import style0 from "./LiveNoStartCenter.vue?vue&type=style&index=0&id=10fadd6b&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10fadd6b",
  null
  
)

export default component.exports