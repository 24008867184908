<template>
    <div class="live-no-start">
        <div class="container" v-if="flag">
            <div class="hint">{{ lang.start_countdown }}</div>
            <div class="count-down">{{ timeTextHint }}</div>
            <el-button class="start-button" @click="startLive" :disabled="!flag">
                <div class="mask" v-if="flag"></div>
                <div class="label">{{ lang.start_live }}</div>
            </el-button>
        </div>
    </div>
</template>

<script>
import lang from "@/lang/liveRoom.js"

export default {
    name: "LiveNoStartCenter",
    data() {
        return {
            lang
        };
    },
    props: {
        viewInfo: {
            type: Object,
            default: null,
        },
        flag: {
            type: Boolean,
            default: true,
        },
    },
    inject: ["toggleDebugDialog"],
    computed: {
        timeTextHint() {
            if (!this.viewInfo) return 0 + this.lang.unit_seconds;
            const liveTime = this.viewInfo.onlive.total_time.total_time;
            let hour = Math.floor(liveTime / 60 / 60);
            let minute = Math.floor(liveTime / 60) % 60;
            let second = liveTime % 60;
            const times = [hour, minute, second];
            const units = [this.lang.unit_hour, this.lang.unit_minute, this.lang.unit_seconds];
            return times.reduce((str, item, index) => str + (item ? `${item}${units[index]}` : ""), "");
        },
    },
    components: {},
    methods: {
        startLive() {
            this.$emit("start-live");
        },
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
.live-no-start {
    position: relative;
    flex: 1;
    display: flex;
    .container {
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 42%;
        .hint {
            font-size: 18px;
            color: #070932;
            font-weight: 700;
        }
        .count-down {
            margin: 40px 0;
            font-size: 36px;
            font-weight: 700;
            color: #060111;
        }
        .start-button {
            position: relative;
            width: 140px;
            height: 50px;
            color: #fff;
            /* line-height: 50px; */
            /* text-align: center; */
            font-size: 16px;
            background-color: #4b45ff;
            border-radius: 25px;
            overflow: hidden;
            z-index: 999;
            border: none;
            /* cursor: pointer; */
            .mask {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                transition: all 0.3s;
                background-color: transparent;
            }
            &:hover .mask {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }
}
</style>
